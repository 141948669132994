<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div v-if="showBackButton" @click="goBack" class="back-btn">
      <img
        :src="
          require(`@/assets/img/${
            backBtnType === '1' ? '' : 'header-'
          }back-btn.png`)
        "
      />
    </div>
    {{ title }}
    <div
      v-if="showCustomerService"
      class="right-block"
      @click="showCustomerServiceModal"
    >
      Customer Service
    </div>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <template #title>
        <div class="customer-service-title">
          <p>Customer Service</p>
          <div @click="onCloseDialog()" class="close-icon">
            <img src="@/assets/img/close.png" alt="关闭" />
          </div>
        </div>
      </template>
      <div class="customer-service-body">
        <div class="line-block">
          <p>Email Customer Service</p>
          <p>sakucs699@gmail.com</p>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'

export default {
  name: 'BaseHeaderBar',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    backBtnType: {
      type: String,
      default: '2'
    },
    title: {
      type: String,
      default: 'Pinjaman'
    },
    showCustomerService: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#FFF'
    },
    bgColor: {
      type: String,
      default: '#2952E5'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },

    showCustomerServiceModal () {
      this.visible = true
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 88px;
  line-height: 88px;
  font-size: 32px;
  text-align: center;
  z-index: 3;
}

.back-btn,
.logout {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;

  img {
    width: 24px;
    height: 40px;
  }
}

.right-block {
  height: 45px;
  line-height: 45px;
  position: absolute;
  right: 31px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 32px;
  color: #fff;
  cursor: pointer;
}

.customer-service-title {
  position: relative;

  p {
    .dialog-title;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 32px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.customer-service-body {
  padding: 32px 25px 54px;

  .line-block {
    display: flex;
    justify-content: space-between;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;

    p {
      font-size: 26px;
      color: #333;
    }

    &:nth-child(n + 2) {
      margin-top: 6px;
    }

    &:nth-child(even) {
      background-color: #f6f4f4;
    }
  }
}
.logout_text {
  color: #000;
  font-size: 46px;
  margin: 60px 0px;
}
</style>
