<template>
  <div class="daichao">
    <img src="@/assets/img/daichao/daichao1.png" />
    <img src="@/assets/img/daichao/daichao4.png" @click="skip('dc')" />
    <img src="@/assets/img/daichao/daichao2.png" @click="skip('brd')" />
    <img src="@/assets/img/daichao/daichao3.png" @click="skip('powers')" />
  </div>
</template>

<script>
export default {
  name: 'Daichao',
  data () {
    return {
    }
  },
  methods: {
    skip (name) {
      switch (name) {
        case 'dc':
          window.location = 'https://apps.apple.com/id/app/kami-cepat-konversi-pinjaman/id6478598354?l=id'
          break
        case 'brd':
          window.location = 'https://apps.apple.com/id/app/brd-meminta-bantuan/id1662191053?l=id'
          break
        case 'powers':
          window.location = 'https://apps.apple.com/id/app/power-s/id6448777370?l=id'
          break
      }
    }
  }
}
</script>

<style scoped lang='less'>
.daichao {
  padding: 0 25px;
  img {
    width: 100%;
    margin: 24px 0;
  }
}
</style>
