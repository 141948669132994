import router from './router'
import store from '@/store'
import { isIOS } from '@/utils/detect'

const cookieUserInfo = localStorage.getItem('userSKInfo')

router.beforeEach((to, from, next) => {
  // 如果是安卓设备则直接跳转到空白页
  if (!isIOS && to.fullPath !== '/androidPage') {
    next({ path: '/androidPage' })
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // apitype  1从akuyuk进入  2从akuaku进入
  const { implementorid, a, b, c, d, e, f, g, h, apitype } = to.query
  if (a || b || c || d || e || f || g || h) {
    const obj = { ...to.query }
    delete obj.ixabisoelinye
    localStorage.setItem('abcdefgh', JSON.stringify(obj))
  }
  if (implementorid) {
    localStorage.setItem('implementorid', implementorid)
  }
  if (apitype) {
    localStorage.setItem('apitype', apitype)
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    const { userSKInfo = {} } = store.state
    const { usdrerID } = userSKInfo
    if (usdrerID) {
      return next()
    }
    if (cookieUserInfo) {
      const u = JSON.parse(cookieUserInfo)
      store.commit('recordInfo', {
        type: 'userSKInfo',
        value: u
      })
      return next()
    }
    next({ path: '/login', query: { redirectUrl: to.fullPath } })
  } else {
    next()
  }
})

// 解决更新代码后 Error:Loading chunk {n} failed.的问题
router.onError(err => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = err.message.match(pattern)
  if (isChunkLoadFailed) {
    const chunkBool = sessionStorage.getItem('chunkError')
    const nowTimes = Date.now()
    if (chunkBool === null || (chunkBool && nowTimes - parseInt(chunkBool) > 60000)) {
      // 路由跳转报错,href手动跳转
      sessionStorage.setItem('chunkError', 'reload')
      const targetPath = router.history.pending.fullPath
      location.href = location.protocol + '//' + location.host + targetPath
    } else if (chunkBool === 'reload') {
      // 手动跳转后依然报错,强制刷新
      sessionStorage.setItem('chunkError', Date.now())
      location.reload(true)
    }
  }
})
export default router
